import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const Service = data => {
  const context = data.pageContext.service.services
  const color = context.serviceDetails.color
  const page_title = data.pageContext.service.title
  const page_subtitle = context.serviceDetails.subtitle
  const firstRow_title = context.firstRow.title
  const firstRow_content = context.firstRow.content
  const firstRow_imageUrl = context.firstRow.image.sourceUrl
  const moreContent = context.moreContent
  const secondRow_imageUrl = context.secondRow.image.sourceUrl
  const secondRow_title1 = context.secondRow.title1
  const secondRow_content1 = context.secondRow.content1
  const secondRow_title2 = context.secondRow.title2
  const secondRow_content2 = context.secondRow.content2
  const secondRow_content3 = context.secondRow.content3

  return (
    <Layout>
      <section
        className="service_header"
        style={{
          backgroundColor: color,
        }}
      >
        <div className="container">
          <h1>{page_title}</h1>
          <span>{page_subtitle}</span>
          <svg
            className="bottom-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 320"
          >
            <path
              fill="#fff"
              fillOpacity=".8"
              d="M-598 16L-503.967 48.2332C-409.933 80.4664 -221.867 144.933 -33.8 183.21C154.267 222.158 342.333 234.917 530.4 183.21C718.467 132.174 906.533 87.1816 1094.6 80.4663C1282.67 74.4226 1470.73 105.984 1658.8 132.174C1846.87 157.692 2034.93 105.984 2128.97 80.4663L2223 54.2769V2726H2128.97C2034.93 2726 1846.87 2726 1658.8 2726C1470.73 2726 1282.67 2726 1094.6 2726C906.533 2726 718.467 2726 530.4 2726C342.333 2726 154.267 2726 -33.8001 2726C-221.867 2726 -409.933 2726 -503.967 2726H-598L-598 16Z"
            ></path>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 320">
            <path
              fill="#fff"
              fillOpacity=".5"
              d="M-122 23.031L-18.3999 55.5407C85.2001 88.0504 292.4 153.07 499.6 191.675C706.8 230.958 914 243.826 1121.2 191.675C1328.4 140.201 1535.6 23.031 1742.8 16.2581C1950 10.1626 2157.2 113.787 2364.4 140.201C2571.6 165.938 2778.8 113.787 2882.4 88.0504L2986 61.6362L2991.5 2752H2887.9C2784.3 2752 2577.1 2752 2369.9 2752C2162.7 2752 1955.5 2752 1748.3 2752C1541.1 2752 1333.9 2752 1126.7 2752C919.5 2752 712.3 2752 505.1 2752C297.9 2752 90.7001 2752 -12.8999 2752H-116.5L-122 23.031Z"
            ></path>
          </svg>
        </div>
      </section>
      <section className="serviceBody">
        <div className="service_btn">
          <div className="container">
            <div
              className="btn-group "
              style={{
                color: color,
              }}
            >
              <Link
                to="/book-a-quote"
                className="btn noLeftMargin"
                style={{
                  backgroundColor: color,
                  borderColor: color,
                }}
              >
                Book a Qoute
              </Link>
              <Link to="/gallery" className="btn btn-secondary noLeftMargin">
                Gallery
              </Link>
            </div>
          </div>
        </div>
        <section className="first-row">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>{firstRow_title}</h3>
                <div dangerouslySetInnerHTML={{ __html: firstRow_content }} />
              </div>
              <div className="col-6">
                <img src={firstRow_imageUrl} alt="" />
              </div>
            </div>
          </div>
        </section>
        {moreContent ? (
          <section className="second-row">
            <div className="container">
              <div className="row reverse">
                <div className="col-6">
                  <img src={secondRow_imageUrl} alt="" />
                </div>
                <div className="col-6">
                  <h3>{secondRow_title1}</h3>
                  <p dangerouslySetInnerHTML={{ __html: secondRow_content1 }} />
                  <h3>{secondRow_title2}</h3>
                  <p dangerouslySetInnerHTML={{ __html: secondRow_content2 }} />
                  <div
                    className="btn-group"
                    style={{
                      color: color,
                    }}
                  >
                    <Link
                      to="/book-a-quote"
                      className="btn noLeftMargin"
                      style={{
                        backgroundColor: color,
                        borderColor: color,
                      }}
                    >
                      Book a Qoute
                    </Link>
                    <Link
                      to="/gallery"
                      className="btn btn-secondary noLeftMargin"
                    >
                      Gallery
                    </Link>
                  </div>
                  <span>{secondRow_content3}</span>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </section>
      <svg
        className="svg-footer"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 320"
      >
        <path
          fill={color}
          fillOpacity=".5"
          d="M-122 23.031L-18.3999 55.5407C85.2001 88.0504 292.4 153.07 499.6 191.675C706.8 230.958 914 243.826 1121.2 191.675C1328.4 140.201 1535.6 23.031 1742.8 16.2581C1950 10.1626 2157.2 113.787 2364.4 140.201C2571.6 165.938 2778.8 113.787 2882.4 88.0504L2986 61.6362L2991.5 2752H2887.9C2784.3 2752 2577.1 2752 2369.9 2752C2162.7 2752 1955.5 2752 1748.3 2752C1541.1 2752 1333.9 2752 1126.7 2752C919.5 2752 712.3 2752 505.1 2752C297.9 2752 90.7001 2752 -12.8999 2752H-116.5L-122 23.031Z"
        ></path>
      </svg>
    </Layout>
  )
}
export default Service
