import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import logo from "./../images/fortis-nav-logo.svg"
import { BsChevronDown } from "react-icons/bs"
import { FaPhoneAlt } from "react-icons/fa"

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className="header container container--big sticky_menu">
      <div className="header__logo">
        <Link to="/">
          <img className="nav-logo" src={logo} alt="fortis logo" />
        </Link>
      </div>
      <nav className="header__nav">
        <span
          className={
            toggleMenu
              ? "header__nav__hamburger nav--active"
              : "header__nav__hamburger"
          }
          role="button"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span></span>
        </span>
        <div className="menu__wrapper">
          <ul className="main__nav">
            <StaticQuery
              query={graphql`
                query MenuQuery {
                  allWpMenu(filter: { id: { eq: "dGVybToyNg==" } }) {
                    edges {
                      node {
                        id
                        menuItems {
                          nodes {
                            path
                            parentId
                            label
                            childItems {
                              nodes {
                                path
                                label
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                  {data.allWpMenu.edges[0].node.menuItems.nodes
                    .filter(item => {
                      return item.parentId == null
                    })
                    .map((item, index) => (
                      <li
                        key={index}
                        className={
                          item.childItems.nodes.length !== 0 ? "dropdown" : ""
                        }
                      >
                        <Link to={item.path}>
                          {item.label}
                          {item.childItems.nodes.length !== 0 ? (
                            <BsChevronDown />
                          ) : (
                            ""
                          )}
                        </Link>
                        {item.childItems.nodes.length !== 0 ? (
                          <ul className="dropdown__menu">
                            {item.childItems.nodes.map((item, index) => (
                              <li key={index} className="dropdown__menu__item">
                                <Link to={item.path}>{item.label}</Link>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                </>
              )}
            />
          </ul>
        </div>
      </nav>
      <div className="header__phone">
        <a href="tel:0467214894">
          <FaPhoneAlt />
          <span>0467 214 894</span>
        </a>
      </div>
    </div>
  )
}

export default Header
