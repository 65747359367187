import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import {
  FaPhoneAlt,
  FaInstagram,
  FaFacebookSquare,
  FaEnvelope,
} from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="section">
      <div className="container container--big">
        <div className="row">
          <div className="col">
            <h2> FORTIS GROUP WA</h2>
            <div className="footer__icon">
              <a href="tel:0467214894">
                <FaPhoneAlt />
                <span>0467 214 894</span>
              </a>
            </div>
            <div className="footer__icon email">
              <a href="mailto:admin@fortisgroupwa.com.au">
                <FaEnvelope />
                <span>admin@fortisgroupwa.com.au</span>
              </a>
            </div>
            <div className="socialmedia">
              <a href="">
                <FaFacebookSquare />
              </a>
              <a href="">
                <FaInstagram />
              </a>
            </div>
          </div>
          <StaticQuery
            query={graphql`
              query FooterMenuQuery {
                main: allWpMenu(filter: { id: { eq: "dGVybToyNg==" } }) {
                  edges {
                    node {
                      menuItems {
                        nodes {
                          path
                          label
                          parentId
                        }
                      }
                    }
                  }
                }
                service: allWpMenu(filter: { id: { eq: "dGVybTo2Ng==" } }) {
                  edges {
                    node {
                      menuItems {
                        nodes {
                          path
                          label
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => (
              <>
                <div className="col">
                  <h3>Navigation</h3>
                  <ul className="footer__nav">
                    {data.main.edges[0].node.menuItems.nodes
                      .filter(item => item.parentId == null)
                      .map((item, index) => (
                        <li key={index}>
                          <Link to={item.path}>{item.label}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="col">
                  <h3>Services</h3>
                  <ul className="footer__nav">
                    {data.service.edges[0].node.menuItems.nodes.map(
                      (item, index) => (
                        <li key={index}>
                          <Link to={item.path}>{item.label}</Link>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </>
            )}
          />
          <div className="col">
            <h3>Licenses</h3>
            <p>
              Electrical <br />
              EC 10113
            </p>
            <p>
              Gas <br />
              GDF 013290
            </p>
            <p>
              Plumbing <br />
              PL 8277
            </p>
          </div>
        </div>
        <div className="row footer__meta">
          <div className="col text-align-left">
            <span>© Fortis Group {new Date().getFullYear()}</span>
          </div>
          <div className="col text-align-right">
            <span>Website by Word of Mouth - Spread the Word</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
